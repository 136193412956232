import { useColorMode } from '@chakra-ui/react';
import React from 'react'

function Social() {
    const { colorMode } = useColorMode;
    const isdark = colorMode == "dark";
    return (
        <div>
            nah
        </div>
    )
}

export default Social
import { Text, Flex, Stack, useColorMode, useMediaQuery, Box, Image, Spacer } from '@chakra-ui/react';
import portraitImage from '../Images/StevenBlytheHeadshot.jpg';
import React from 'react'

function Header() {
    const { colorMode } = useColorMode;
    const isdark = colorMode === "dark";

    const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");

    return (
        <Stack>
            <Flex   direction={isNotSmallerScreen ? "row" : "column"}
                    p={isNotSmallerScreen ? "32" : "0"}
                    alignSelf="flex-start"
                    >
                <Box mt={isNotSmallerScreen ? "0" : 16} align="flex-start" direction="column">
                    <Text fontSize="5xl" fontWeight="semibold">Hello there, I'm</Text>
                    <Text fontSize="7xl" fontWeight="bold" bgGradient="linear(to-r, cyan.400, blue.500, purple.600)" bgClip='text'>Steven Blythe</Text>
                </Box>
                <Spacer />
                <Image  
                    alignSelf="center" 
                    mt={isNotSmallerScreen ? "0" : "12"} 
                    mb={isNotSmallerScreen ? "0" : "12"} 
                    borderRadius="full" 
                    backgroundColor="transparent" 
                    boxShadow="1g" 
                    width="300px"
                    src={portraitImage} 
                    alt="Portrait of Steven Blythe"
                />
            </Flex>
        </Stack>
    )
}

export default Header
import { useColorMode } from '@chakra-ui/react';
import React from 'react'

function Profile() {
    const { colorMode } = useColorMode;
    const isdark = colorMode == "dark";
    return (
        <div>
            oh
        </div>
    )
}

export default Profile